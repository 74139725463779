import { Component, OnInit } from '@angular/core';
import { Comp } from '../comp';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  comps: Comp[];
  compFooter: Comp;

  constructor(private http: HttpClient) {
    this.http.get("/assets/tevekkel.com/modules.json").subscribe(resp => {
        var dt = JSON.parse(JSON.stringify(resp))
        
        this.comps = dt.Components;

        
        this.compFooter = this.comps.filter(function(comp) {
          return comp.ComponentId == 11;
        })[0];
    });
   }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Banner } from '../banner';
import { Comp } from '../comp';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  banners: Banner[];
  comps: Comp[];
  compBannerAlt: Comp;
  compUclu: Comp;
  compUcluAlt: Comp;

  constructor(private http: HttpClient) {
    this.http.get("/assets/tevekkel.com/modules.json").subscribe(resp => {
        var dt = JSON.parse(JSON.stringify(resp))
        
        this.banners = dt.Banners;
        this.comps = dt.Components;

        
        this.compBannerAlt = this.comps.filter(function(comp) {
          return comp.ComponentId == 8;
        })[0];

        this.compUclu = this.comps.filter(function(comp2) {
          return comp2.ComponentId == 9;
        })[0];

        this.compUcluAlt = this.comps.filter(function(comp3) {
          return comp3.ComponentId == 10;
        })[0];
    });
   }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Page } from '../page';

@Component({
  selector: 'app-pagedetail',
  templateUrl: './pagedetail.component.html',
  styleUrls: ['./pagedetail.component.css']
})
export class PagedetailComponent implements OnInit {

  page: Page;
  pages: Page[];

  constructor(
    private route: ActivatedRoute,private http: HttpClient
  ) { 

    

  }

  ngOnInit(): void {

    let id = this.route.snapshot.paramMap.get('pageId');
      
    this.http.get("/assets/tevekkel.com/modules.json").subscribe(resp => {
      var dt = JSON.parse(JSON.stringify(resp))

      this.page = dt.Pages.filter(function(page) {
        return page.PageId == id;
      })[0];
  });
  }
}

<div class="slider-container rev_slider_wrapper" style="height: 100vh; background: white;">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li *ngFor="let b of banners; let j = index" data-target="#carouselExampleIndicators"  class="{{ j == 0 ? 'active' : ''}}"></li>
        </ol>
        <div class="carousel-inner" role="listbox">
          <!-- Slide One - Set the background image for this slide in the line below -->
          <div *ngFor="let banner of banners; let i = index" class="carousel-item {{ i == 0 ? 'active' : ''}}" style="background-image: url('{{banner.Photo}}')">
            <div class="carousel-caption">
              <h2 class="display-4">{{ banner.Text1 }}</h2>
              <p class="lead">{{ banner.Text2 }}</p>
            </div>
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
      </div>
</div>

<section *ngIf="compBannerAlt" class="section section-center section-no-border section-light" [innerHTML]="compBannerAlt.Content | keepHtml">

</section>

<div *ngIf="compUclu" [innerHTML]="compUclu.Content | keepHtml" class="container-fluid">
    
</div>

<section *ngIf="compUcluAlt" [innerHTML]="compUcluAlt.Content | keepHtml" class="section section-center section-no-border section-light">
    
</section>

<!-- Google Maps - Go to the bottom of the page to change settings and map location. -->
<div id="googlemaps" class="google-map m-0" style="height: 425px;">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2134.1258609036654!2d31.152017127714487!3d40.847514451422434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x409d755b02c6ef3d%3A0x2a2e70ff5f1b4bae!2sTevekkel%20Oto!5e0!3m2!1str!2str!4v1596567000911!5m2!1str!2str" width="100%" height="425" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
</div>

<app-footer></app-footer>
<section *ngIf="page" class="section section-no-border section-light custom-padding-top-1">
    <div class="container">
        <div class="row mt-4">
            <div class="col-md-12 col-lg-12">
                <h1 class="font-weight-bold text-color-primary mb-0">{{ page.Title }}</h1>
                <div style="margin-top: 20px;" [innerHTML]="page.Content | keepHtml"></div>
                <!-- {{page.Content}} -->
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>